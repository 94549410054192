#credits {
  padding-top: 100px;
  background-color: black;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
}

.creditsLogo {
  width: 150px;
  height: auto;
  margin: 50px 100px 50px 100px;
}