.easy-print-NoPrint-root-1A10 {
  height: 100px;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.easy-print-NoPrint-root-1A10 p {
  padding-left: 2em;
  padding-right: 2em;
  font-size: 1.2em;
}

.easy-print-NoPrint-root-1A10 a {
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  opacity: 0.8;
  text-decoration: none !important;
}

.easy-print-NoPrint-root-1A10 a:hover {
  text-shadow: 1px 1px grey;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

#footer p {
  font-family: "Audrey-Normal";
}