.mapGrapeHover {
  opacity: 0.6;
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.mapGrapeHover:hover {
  cursor: pointer;
  opacity: 1;
  transition: all .2s ease-in-out;
  stroke: rgba(255, 255, 255, 0.9);
  stroke-width: 0.5;
}

.flexContainer {
  display: flex;
  justify-content: center;
}

.franceWinesMap {
  min-width: 50vw;
  width: 75vw;
  height: 100vw;
}