.regionName {
    color: grey;
    font-size: 1.2em;
    font-family: 'Audrey-Normal';
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 25px;
}

.flexHorizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: justify;
    margin-top: 50px;
}

.regionListContainer {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    box-shadow: 0 2px 2px 0 rgba(151, 80, 109, 0.16), 0 0 0 1px rgba(151, 80, 109, 0.08);
    cursor: pointer;
    background-color: white;
    transition: .5s ease;

}

.regionListContainer:hover {
    background-color: rgba(200, 200, 200, 0.8);
    transition: .5s ease;
}