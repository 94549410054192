.cmsQuote {
    padding: 5em;
}

#winesMobileLogo {
    display: none;
}

.card {
    border: 0px;
}

@media (max-width: 1099px) {
    
    #page-wrap {
        padding: 100px 0px 20px 0px;
    }

    .menu {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        height: 180px;
        padding: 0;
        margin: auto;
        z-index: 1000;
    }

    .menu a {
        padding: 10px;
    }

    #sloganAbout h1 {
        font-size: 2.5em;
    }

    #sloganAbout h2 {
        font-size: 1.5em;
    }

    .mapLink {
        padding-top: 10px;
    }

    .domaineSpec {
        align-items: center;
    }

    .domainesProdContainer {
        align-items: center;
        justify-content: center;
    }

    .domaineInfoContainer {
        padding-top: 180px;
    }

    .easy-print-NoPrint-root-1A10 {
        height: 250px;
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #vintners {
        padding-left: 20px;
        padding-right: 20px;
        overflow: hidden;

    }

    #vintners h1 {
        font-size: 1.5em;
    }

    #vintners p {
        font-size: 1em;
    }


    .winesListByRegion {
        justify-content: center;
    }

    #balanceLightLoveContainer div {
        padding-top: 30px;
    }

    #balanceLightLoveContainer {
        padding-top: 0;
    }

}

@media screen and (orientation:landscape) {
    #alineBioBackground {
        position: fixed;
        top: 0;
        width: 100vw !important;
        height: auto !important;
        z-index: -100;
        filter: blur(3px);
        -webkit-filter: blur(1px);
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
        -ms-transform: scale(1.05);
        -o-transform: scale(1.05);
    }
}

@media (max-width: 1182px) {
    #wineTechSheetHeaderLogo {
        padding-right: 70%;
    }
    #slogan { 
        padding: 50px 20px 100px 20px;
    }

    #sloganAbout {
        padding-top: 0;
        padding-bottom: 0;
        height: 50vh;
    }

    #sloganAbout h1 {
        font-size: 1.4em;
    }

    #sloganAbout h2 {
        font-size: 1em;
    }

    .cmsQuote {
        padding: 3em;
    }

    #homeSection, #aboutSection, .ourWines, .winesCardsContainer {
        padding-top: 180px;
    }

    .wineTechSheet {
        padding: 120px 20px 20px 20px;
    }
    .modalWineSpec {
        max-width: 1000px;
    }
    
}

@media screen and (max-width: 850px) {
    .wineTechSheet {
        max-width: 700px;
        padding: 120px 10px 20px 10px;
    }
    .modalWineSpec {
        max-width: 700px;
    }

}

@media (max-width: 746px) {

    #logoMenu {
        position: absolute;
        right: 40%;
        top: 10px;
    }
    .modalWineSpec {
        max-width: 700px;
    }

    #aboutAliane div {
        padding-left: 0px;
        padding-right: 0px;
    }
    #sloganMoto h1 {
        font-size: 1.8em;
    }

    #mainSlogan {
        margin: 5px;
        text-align: center;
    }

    #sloganAbout h2 {
        text-align: center;
    }

    #sloganMoto h2 {
        font-size: 1em;
    }

    #sloganMoto h3 {
        font-size: 1em;
    }

    .searchListResults {
        min-width: 100vw;
        padding: 0 !important;
        margin-top: 20px;
    }

    .searchResultIcons {
        height: 40px;
        width: 40x;
    }

    .mapLink {
        padding-top: 0px;
    }

    .domaines {
        padding: 1em;
    }

    #vintners {
        padding-bottom: 0;
        margin-bottom: 0;
    }


    .ContactUs-logo-2 {
        width: 140px !important;
        height: auto;
    }

    .wineTechSheetContent li p {
        letter-spacing: 0.1em;
    }

    .cmsQuote {
        padding: 0em;
    }

    #homeSection, #aboutSection, .ourWines, .winesCardsContainer {
        padding-top: 100px;
    }

}

@media (max-width: 652px) {
    #vintners {
        padding-top: 0;
    }
    #slogan {
        justify-content: flex-start;
    }
    #page-wrap {
        padding: 5px 0px 20px 0px;
        overflow-x: hidden;
    }

    .domaineInfoContainer {
        padding-top: 100px;
    }

    .menu {
        height: 100px;
    }

    .mobileMenu {
        display: flex;
        flex-direction: column;
        top: 100px;
        width: 100vw;
        background-color: rgba(255, 255, 255, 1);
        position: absolute;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    #menuIcon {
        color: #97506d;
        position: absolute;
        top: 25px;
        right: 40px;
        height: 50px;
        width: auto;
    }

    .mobileMenu p {
        color: #BC204B !important;
    }

    #logoMenu {
        display: none;
    }

    .wineTechSheet {
        padding: 20px 20px 20px 20px;
    }


    .leftCell {
        width: auto;
    }

    .rightCell {
        width: auto;
        max-width: 100%;
        padding-left: 0px;
    }

    .wineTechSheetHeaderText {
        margin-left: 10px;
    }

    .wineTechSheetHeaderText p {
        font-size: 10px;
    }

    .wineDenomination p {
        padding-left: 0 !important;
    }

    .menuHover {
        display: none;
    }

    .regionSpec {
        padding: 20px;
    }

    .menuSocialIcons {
        margin-right: 0px;
        margin-left: 0px;
    }

    #socialIconsMenu {
        display: none;
        margin-top: 20px;
    }

    .card {
        border: 0px;
    }

    .contactCard {
        margin: 10px 0px 10px 0px;
        padding: 5px;
        width: 100vw;
        height: auto;
    }

    #grandCruBanner,
    #grandCruAwards {
        padding: 0;
    }

    .gcCredits {
        text-align: center;
    }

    #gcComponent h1 {
        text-align: center;
    }

    .domaineImg {
        width: 100%;
        height: auto;
        padding-top: 10px;
    }

    .regionImg {
        width: 100%;
        height: auto;
    }

    .flexHorizontal {
        justify-content: center;
    }


    #introduction p {
        margin-top: 20px;
    }

    #sloganAbout h1 {
        font-size: 1.4em;
    }

    #sloganAbout h2 {
        font-size: 1em;
    }

    #bioTextContainerNext div {
        padding-left: 0px;
        padding-right: 0px;
    }

    #purpleLogo {
        width: 200px;
        height: auto;
    }

    #footerSlogan {
        padding: 0;
        font-size: 0.5em !important;
    }

    #technicalSheetFlexFooter {
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;
    }

    .technicalSheetFlexFooterBorder {
        display: none;
    }

    #wineTechSheetHeaderLogo {
        padding-right: 0;
    }

    #signature {
        display: none;
    }

    #technicalSheetMobileFooter {
        padding: 0;
        margin: 0;
    }

    .wineTechSheetContent li {
        flex-direction: column;
    }

    #technicalSheetMobileFooter {
        flex-direction: column;
    }

    #footerSlogan {
        padding-top: 10px;
    }

    .wineTechSheetVignette {
        padding: 0;
        padding-bottom: 30px;
        width: 250px;
        height: auto;
    }

    .wineDenomination p {
        text-align: center;
    }
    #mobileLogo {
        display: block;
        width: 240px;
        height: auto;
        margin: auto;
        padding: 20px;
    }
    #winesMobileLogo {
        display: block;
        width: 240px;
        height: auto;
        margin: auto;
    }
}

@media (min-width: 653px) {
    .mobileMenu {
        display: none;
    }

    #menuIcon {
        display: none;
    }

    #technicalSheetMobileFooter {
        display: none;
    }

    #footerSlogan {
        display: none;
    }
}