.searchBox {
    z-index: 1001 !important;
    position: absolute;
    left: 20px;
    top: 25px;
}

.searchBox input {
    border: 0px solid;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: white;
    width: 100px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 10000 !important;
}

.input:focus {
    outline: 0 none;
}


.searchListResults {
    list-style-type: none;
    position: absolute;
    z-index: 1001;
    background-color: rgba(250, 250, 250, 0.9);
    min-width: 96vw;
    margin-top: 50px;
}

.searchListResults li {
    margin: 0.7%;
    color: #BC204B;
    cursor: pointer;
}

.searchListResults li:hover {

    color: #BC204B;
}

.searchResultIcons {
    height: 50px;
    width: 50x;
}

.searchHover:hover {
    background-color: rgba(0, 0, 0, 0, 0.5);
}

@media (max-width: 746px) {
    .searchListResults {
        min-width: 100vw;
        padding: 0 !important;
        margin-top: 20px;
    }

    .searchResultIcons {
        height: 40px;
        width: 40x;
    }
}
#logoMenu {
  height: 70px;
  width: auto;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  height: 90px;
  padding: 0;
  margin: auto;
  z-index: 1000;
  font-family: "Aline";
}

.menu a {
  padding: 20px;
  text-decoration: none;
  font-size: 1.4em;
  font-family: "Audrey-Normal";
  font-weight: 300;
  text-transform: uppercase;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  opacity: 0.7;
  text-decoration: none !important;
}

.menuHover:after {
  content: '';
  display: block;
  margin: auto;
  height: 1px;
  width: 0px;
  background: transparent;
  transition: width 1s ease, background-color .5s ease;
  -webkit-transition: width 1s ease, background-color .5s ease;
  -moz-transition: width 1s ease, background-color .5s ease;
  -ms-transition: width 1s ease, background-color .5s ease;
  -o-transition: width 1s ease, background-color .5s ease;
}

.menu a p {
  margin: 0;
  text-align: center;
  font-weight: 400;
  font-family: "Audrey-Medium";
}

.menuHover:hover {
  transform: scaleX(1, 2);
  -webkit-transform: scaleX(1, 2);
  -moz-transform: scaleX(1, 2);
  -ms-transform: scaleX(1, 2);
  -o-transform: scaleX(1, 2);
  font-size: 1.6em;
  opacity: 1;
}

#gcBanner {
  cursor: pointer;
  width: 40px;
  height: auto;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 25px;
  z-index: 10000;
}

#igLogo {
  cursor: pointer;
  width: 40px;
  height: auto;
  z-index: 10000;
  margin-bottom: 25px;
}

#socialIconsMenu {
  position: absolute;
  right: 20px;
  top: 30px;
}

.menuSocialIcons {
  margin-right: 10px;
  margin-left: 10px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  color: white;
  opacity: 0.9;
}

.menuSocialIcons:hover {
  color: white;
  opacity: 1;
}

.editableBanner p {
  color: white;
}

.saveButton {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.gcContainer #gcBannerText {
  visibility: hidden;
  position: absolute;
  top: 75px;
  width: 220px;
  font-size: 0.8em;
  right: 80;
  transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  text-align: center;
  padding: 5px;
  color: white;
}

.gcContainer:hover #gcBannerText {

  visibility: visible;
  color: white;
  background-color: gray;
  text-align: center;
  transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
}

#gcBanner {
  cursor: pointer;
  width: 110px;
  height: auto;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 10000;
}

.react-reveal {
  height: 80px;
}

.menuMobileLogo {
  margin-top: 20px;
}


.gcContainer[href="/grand-cru"] {
  opacity: 1.0;
}

.socialIconsMenu {
  background-color: white;
  border-radius: 15px;
  opacity: 1.0 !important;
}

.menuWhite .socialIconsMenu {
  background-color: transparent;
  border-radius: 15px;
  opacity: 1.0 !important;
}

#igLogo {
  opacity: 0.7;
}
.easy-print-NoPrint-root-1A10 {
  height: 100px;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.easy-print-NoPrint-root-1A10 p {
  padding-left: 2em;
  padding-right: 2em;
  font-size: 1.2em;
}

.easy-print-NoPrint-root-1A10 a {
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  opacity: 0.8;
  text-decoration: none !important;
}

.easy-print-NoPrint-root-1A10 a:hover {
  text-shadow: 1px 1px grey;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

#footer p {
  font-family: "Audrey-Normal";
}
#slogan {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  padding: 100px 20px 100px 20px;
}

#slogan h1,
#slogan h2,
#slogan h3 {
  color: white;
  word-break: keep-all;
}

#slogan h1 {
  font-size: 2.25em;
  color: white;
  text-align: center;
  word-spacing: 0.2em;
  margin: 0.2em;
}

.vineTitle {
  width: 50px !important;
  height: auto;
  padding: 5px;
}

#homePage {
  position: relative;
}

#introduction {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2em 0 2em;
}

#introduction h1 {
  color: #BC204B;
  text-align: center;
  font-size: 2em;
  text-transform: uppercase;
}

.fullHomeImg {
  width: 100%;
  height: auto;
}

.homeFlexHorizontal {
  display: flex;
  align-items: center;
}

#sloganMoto {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.8);
}

#sloganMoto h3 {
  color: #25272B;
  text-align: center;
  font-size: 1.5em;
  padding: 0 20px 0 20px;
  word-break: keep-all;
}

#balanceLightLoveContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-wrap: wrap;
}

#balanceLightLoveContainer div {
  text-align: center;
  width: 350px;
}

#balanceLightLoveContainer h2 {
  color: #BC204B;
  text-transform: uppercase;
  font-family: 'Audrey-Normal';
}

#balanceLightLoveContainer h1 {
  color: #25272B;
  text-transform: uppercase;
  font-family: "Audrey-Normal";
}

#balanceLightLoveContainer p {
  color: #25272B;
  text-transform: uppercase;
  padding: 10px;
  text-align: center;
  color: #25272B;
  font-size: 1.3em;
}

.borderWidthed {
  border-bottom: solid #BC204B;
  max-width: 40px;
  margin: auto;
  border-bottom-width: 1px;
}

#mainSlogan {
  color: #BC204B;
  margin-top: 1em;
  margin-bottom: 1.5em;
  font-family: "Audrey-Medium";
  font-size: 1.5em;
  text-transform: uppercase;
}

#logoEncart {
  width: 160px;
  height: 200px;
  padding-left: 20px;
}

#flexMoto {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2em;
}

.audreyFontUppercase {
  font-family: "Audrey-Normal";
  text-transform: uppercase;
}

.audreyFont {
  font-family: "Audrey-Normal";
}

.normalAudrey {
  font-family: "Audrey-Normal"
}

.mainSloganContent {
  font-family: "GillSans-Light";
  font-size: 1.3em;
}

.asterisk {
  font-family: "GillSans-Light";
  font-size: 20px;
  display: inline-block;
  top: -15px;
  position: relative;
}

#bio h1 {
  font-family: 'GillSans-Light';
  color: black;
  text-align: center;
}

#sloganAbout h1 {
  text-transform: none;
  color: black;
  text-align: center;
  font-family: 'GillSans-Light';
  font-size: 2em;
}


#photoAline {
  max-width: 30vw;
  min-width: 300px;
  z-index: -100;
  margin-bottom: 20px;
}

#bioTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: white;
  min-height: 100vh;
  text-align: justify;
}

#bioTextContainer div,
#aboutAliane > div {
  max-width: 80vw;
  min-width: 340px;
  padding-left: 20px;
  padding-right: 20px;
  color: grey;
}

#bioTextContainer div p {
  font-size: 1.3em;
}

#bioTextContainer div h1 {
  text-align: center;
}

#bioTextContainer h1,
#bioTextContainer p {
  color: rgb(46, 46, 46);
}

.contentPanel {
  padding-top: 50px;
  padding-bottom: 50px;
}

.contentPanel div {
  padding-bottom: 10px;
}

#bioTextContainerNext {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: #ffffffc0;
  text-align: justify;
}

#bioTextContainerNext div {
  max-width: 80vw;
  min-width: 340px;
  padding-left: 30px;
  padding-right: 30px;
}

#bioTextContainerNext div p {
  font-size: 1.3em;
}

#aboutAliane {
  min-height: 100vh;
  background-color: #ffffffc0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
}

#aboutAliane div {
  max-width: 80vw;
  min-width: 340px;
  padding-left: 20px;
  padding-right: 20px;
}

#aboutAliane p {
  color: rgb(46, 46, 46);
  margin-top: 0;
  font-size: 1.3em;
}

#springVineyard {
  width: 100%;
  height: auto;
  padding: 0;
}

#purpleLogo {
  width: 15vw;
  padding: 2vw;
}

#bioPhotoSection h1 {
  text-align: center;
  font-family: 'GillSans-Light';
}

#directLinks {
  background-color: white;
  padding: 0px 30px 50px 30px;
}

.aboutDirectLinkToAllWines {
  text-align: center;
  padding: 20px;
  color: rgba(0, 0, 0, 0.8);
  opacity: 0.8;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.aboutDirectLinkToAllWines:hover {
  opacity: 1;
  font-weight: 300;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.whiteborderAbout {
  border-bottom: solid rgba(0, 0, 0, 0.6);
  width: 150px;
  margin: auto;
  border-bottom-width: 1px;
}
#selectedVinters {
  color: grey;
  padding: 3em;
}

#selectedVinters {
  color: grey;
  font-size: 1em;
  font-family: 'GillSans-Light', sans-serif;
  font-weight: 300;
}

#selectedVinters h1 {
  color: black;
  font-family: 'GillSans-Light', sans-serif;
}


.card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.regionSpec {
  min-width: 350px;
  padding: 20px;
  text-align: justify;
}

.regionImg {
  width: 300px;
  height: auto;
  background-color: darkgrey;
}

.domaineVignette {
  position: relative;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  cursor: pointer;
  filter: grayscale(100%);
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.domaineVignette:hover {
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  filter: none;
  -webkit-filter: none;
}

.domaineImg {
  padding: 2px;
  width: 300px;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
}

.domaineName {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 1.2em;
  width: 50%;
}

.regionWinesList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

#burgundyDetailedMap {
  max-width: 800px;
  padding: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#provenceDetailedMap {
  max-width: 1000px;
  margin-top: 20px;
  padding: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#rhoneDetailedMap {
  max-width: 800px;
  margin-top: 20px;
  padding: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.deleteButton {
  background-color: #BC204B;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
}
.breadcrumb {
    color: #BC204B;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    flex-wrap: wrap;
    padding-left: 60px;
    background-color: white !important;
}

.breadcrumb a {
    text-decoration: none !important;
    font-size: 1.2em;
    white-space: nowrap;
    margin-left: 10px;
}

.chevronRight {
    margin-right: 7px;
    height: 1em;
    width: auto;
    stroke: #BC204B;
    margin-bottom: 7px;

}

.mapLink {
    padding-top: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.breadCrumbLink {
    margin-bottom: 0 !important;
    font-family: "Audrey-Normal";
    opacity: 0.7;
}

.breadCrumbLink:hover {
    margin-bottom: 0 !important;
    opacity: 1;
}
.mapGrapeHover {
  opacity: 0.6;
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.mapGrapeHover:hover {
  cursor: pointer;
  opacity: 1;
  transition: all .2s ease-in-out;
  stroke: rgba(255, 255, 255, 0.9);
  stroke-width: 0.5;
}

.flexContainer {
  display: flex;
  justify-content: center;
}

.franceWinesMap {
  min-width: 50vw;
  width: 75vw;
  height: 100vw;
}
.mapContainerFlexHorizontal {
  padding-top: 0px;
}

#vintners {
  padding: 3em;
  padding-top: 120px;
  font-family: 'GillSans-Light';
  font-weight: 300;
  font-size: 1.2em;
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

#vintners > div {
  max-width: 1000px;
}

#vintners p {
  color: #25272B;
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  margin: 30px;
  font-size: 1.1em;
}

#vintners h1 {
  color:  #BC204B;
  font-family: 'GillSans-Light';
  text-align: center;
  width: 100%;
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  padding: 50px 50px 10px 50px;
}


.mapContainerFlexHorizontal div h1 {
  color: #BC204B;
  font-family: 'GillSans-Light';
  text-align: center;
  max-width: 1000px;
  text-align: center;
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  padding: 50px;
  margin: auto;
}


.menuSocialIcons {
  color: rgba(151, 80, 109);
}

#directLinkToAllWines {
  text-align: center;
  color: #BC204B !important;
  padding-top: 1em;
  opacity: 0.8;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  padding-left: 20px;
  padding-right: 20px;
}

#directLinkToAllWines:hover {
  opacity: 1;
  font-weight: 300;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

main#page-wrap>div>div>div>h1:nth-of-type(2) {
  padding: 5px;
}

.borderWines {
  border-bottom: solid #BC204B;
  max-width: 150px;
  margin: auto;
  padding: 10px;
  border-bottom-width: 1px;
}
.regionName {
    color: grey;
    font-size: 1.2em;
    font-family: 'Audrey-Normal';
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 25px;
}

.flexHorizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: justify;
    margin-top: 50px;
}

.regionListContainer {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    box-shadow: 0 2px 2px 0 rgba(151, 80, 109, 0.16), 0 0 0 1px rgba(151, 80, 109, 0.08);
    cursor: pointer;
    background-color: white;
    transition: .5s ease;

}

.regionListContainer:hover {
    background-color: rgba(200, 200, 200, 0.8);
    transition: .5s ease;
}
.franceWinesMap {
  width: 75%;
  min-width: 50%;
  margin-top: 30px;
}

.ourWines {
  min-height: 100vh;
}

.backgroundImg {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100vh;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.gmapCustom {
  filter: grayscale();
}

#credits {
  padding-top: 100px;
  background-color: black;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
}

.creditsLogo {
  width: 150px;
  height: auto;
  margin: 50px 100px 50px 100px;
}
.winesCardsContainer {
    padding: 0px 10px 100px 10px;
    display: flex;
    flex-direction: column;

}

.winesListByRegionTitle {
    text-transform: uppercase;
    color: #BC204B;
    font-size: 2em;
    border: 1px solid #BC204B;
    text-align: center;
    padding: 10px 20px 10px 20px;
    max-width: 1000px;
}

.winesListByRegionTitle:hover {
    background-color: #BC204B;
    color: white;
    transition: 1s;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -ms-transition: 1s;
    -o-transition: 1s;
    cursor: pointer;
}

.winesListByRegion {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    padding-top: 20px;
}

.winesCardsVignette {
    width: 300px;
    text-align: center;
    margin: 10px;
}

.winesCardsVignette:hover {
    color: #BC204B;
    cursor: pointer;
}

.WinesListContainer {
    padding-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.styles_overlayCenter__YHoO7 {
    z-index: 1001 !important;
}

.styles_modal__gNwvD {
    padding: 0;
}

.allOurWineVignette {
    height: auto;
    width: 250px;
}

#mobileLogo {
    display: none;
}
#gcComponent {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 160px;
  background-color: black;
}

#gcComponent h1 {
  color: white;
  position: relative;
  font-size: 2em;
  padding-top: 10px;
}

#gcComponent > h1:first-of-type {
  max-width: 400px;
  text-align: center;
}

#gcComponent p, .gcContent {
  padding: 5%;
  font-size: 1.3em;
  color: white;
  text-align: justify;
  text-justify: inter-word;
  max-width: 1000px;
  text-indent: 5%;
  max-width: 80vw;
}

.watchIt {
  color: rgb(188, 32, 75);
  font-size: 60px;
}

a:hover {
  text-decoration: none;
}

#grandCruBanner {
  background-color: black;
  width: 100%;
  padding: 0 100px 0 100px;
  margin-top: 30px;
}

#grandCruAwards {
  background-color: black;
  width: 100%;
  padding: 0 100px 0 100px;
}

.gcPartnersLogos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.gcPartnersLogos img {
  width: 200px;
  height: auto;
}

.gcCredits {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 150px 50px 150px;
}

.gcCredits div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-width: 350px;
  align-items: center;
  padding: 10px 15px 10px 15px;
}

.gcCredits h2 {
  color: white;
  font-size: 12px;
}

.gcCredits h1 {
  font-size: 22px !important;
}
.domaines {
  padding: 3em;
}

.domaineName {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 1.2em;
  width: 50%;
}

.wineTechSheet {
  padding: 20px;
}

.wineTechSheetHeader {
  display: flex;
  align-items: center;
}

.wineTechSheetHeaderText {
  margin-left: 40px;
}

.wineTechSheetHeaderText p {
  color: gray;
  text-align: center;
  font-size: 12px;
}

.wineTechSheetHeaderTextBorder {
  border: 0.5px solid rgba(128, 128, 128, 0.5);
}

.wineTechSheetContent {
  padding-left: 0px !important;
}

.wineTechSheetContent li {
  list-style-type: none;
  padding-bottom: 10px;
  font-weight: 200;
  text-align: justify;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-left: 0 !important;
}

.wineTechSheetContent li p {
  color: gray;

}

.wineDenomination p {
  font-weight: 300 !important;
  text-transform: uppercase;
  padding-left: 10px !important;
  color: black !important;
}

.rightCell {
  max-width: 80%;
  padding-left: 10px;
}

.leftCell {
  width: 18%;
  color: black !important;

}

.domaineSpec {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.domaineProduction {
  list-style-type: none;
  color: grey;
  font-weight: 200;
  max-width: 250px;
  margin-left: 10px;
  margin-right: 10px;
}

.domainesProdContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 50px 0px 50px 0px;
  flex-wrap: wrap;
}

.productionVignette {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.domaineProduction p:hover {
  color: #BC204B;
  cursor: pointer;
}

.domaineTitle {
  color: #BC204B;
  text-transform: uppercase;
  font-size: 1.5em;
  text-align: center;
  font-weight: bold;
}

.domainePlace {
  text-transform: uppercase;
  font-size: 1.2em;
  text-align: center;
  font-weight: bold;
}

.domaineDescription {
  text-align: justify;
  text-justify: newspaper;
  transition: 0.3s;
  padding: 20px;
  color: #25272B;
  font-size: 1.2em;
}

.wineVignette {
  height: auto;
  width: 250px;
}

.wineVignette:hover {
  cursor: pointer;
}

.DomaineHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wineVignetteRegion {
  text-transform: uppercase;
  font-weight: 400 !important;
}

#beauneDetailedMap,
#nuitDetailedMap {
  width: 100%;
  padding: 40px 0px 40px 0px;
}

.image-gallery-thumbnail.active {
  border: none !important;
}

.image-gallery-fullscreen-button:focus {
  outline: none !important;
}
.modalWineSpec {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.wineTechSheet {
  padding: 20px 50px 50px 50px;
  max-width: 1080px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.wineTechSheetHeaderText {
  margin-left: 20px;
}

.wineTechSheetHeaderText p {
  color: gray;
  text-align: center;
  font-size: 12px;
}

.wineTechSheetHeaderTextBorder {
  border: 0.5px solid rgba(128, 128, 128, 0.5);
  margin-bottom: 20px;
}

.wineTechSheetContent {
  padding-left: 0px !important;
}

.wineTechSheetContent li {
  list-style-type: none;
  padding-bottom: 10px;
  text-align: justify;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-left: 0 !important;
}

.wineTechSheetContent li p {
  color: gray;

}

.wineDenomination p {
  font-weight: bold !important;
  text-transform: uppercase;
  padding-left: 10px !important;
  color: black !important;
  font-size: 1em;
  font-family: 'GillSans-Light';
  margin-bottom: 40px;
}

.rightCell {
  max-width: 80%;
  padding-left: 10px;
}

.leftCell {
  width: 18%;
  font-family: 'GillSans-Light';
}

#wineTechSheetHeaderLogo {
  height: 100px;
  margin-bottom: 30px;
  padding-right: 90%;
}

.wineTechSheetVignette {
  height: 300px;
  width: auto;
  padding: 30px;
}



#technicalSheetMobileFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

#technicalSheetMobileFooter h4 {
  font-size: 1em !important;
  font-family: "Audrey-Normal" !important;
  padding: 6px;
  margin-top: 8px;
}

.technicalSheetFlexFooterBorder {
  border-right: solid #BC204B;
  width: 10px;
  border-right-width: 1.5px;
  margin: 12px;
}

.mobileSignature{
  padding: 6px;
  width: 80vw;
}

.href h4 {
  color: #25272B;
}

.href h4:hover {
  color: #25272B;
}

#footerSlogan {
  text-transform: uppercase;
  color: #BC204B;
  font-size: 1em !important;
  padding-top: 10px;
  text-align: center;
  font-family: "Audrey-Normal" !important;
  word-spacing: 0.4em;
  letter-spacing: 0.5em;
  padding-left: 80px;
  padding-right: 80px;
}

#signature {
  min-width: 500px;
}
.cmsQuote {
    padding: 5em;
}

#winesMobileLogo {
    display: none;
}

.card {
    border: 0px;
}

@media (max-width: 1099px) {
    
    #page-wrap {
        padding: 100px 0px 20px 0px;
    }

    .menu {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        height: 180px;
        padding: 0;
        margin: auto;
        z-index: 1000;
    }

    .menu a {
        padding: 10px;
    }

    #sloganAbout h1 {
        font-size: 2.5em;
    }

    #sloganAbout h2 {
        font-size: 1.5em;
    }

    .mapLink {
        padding-top: 10px;
    }

    .domaineSpec {
        align-items: center;
    }

    .domainesProdContainer {
        align-items: center;
        justify-content: center;
    }

    .domaineInfoContainer {
        padding-top: 180px;
    }

    .easy-print-NoPrint-root-1A10 {
        height: 250px;
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #vintners {
        padding-left: 20px;
        padding-right: 20px;
        overflow: hidden;

    }

    #vintners h1 {
        font-size: 1.5em;
    }

    #vintners p {
        font-size: 1em;
    }


    .winesListByRegion {
        justify-content: center;
    }

    #balanceLightLoveContainer div {
        padding-top: 30px;
    }

    #balanceLightLoveContainer {
        padding-top: 0;
    }

}

@media screen and (orientation:landscape) {
    #alineBioBackground {
        position: fixed;
        top: 0;
        width: 100vw !important;
        height: auto !important;
        z-index: -100;
        filter: blur(3px);
        -webkit-filter: blur(1px);
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
        -ms-transform: scale(1.05);
        -o-transform: scale(1.05);
    }
}

@media (max-width: 1182px) {
    #wineTechSheetHeaderLogo {
        padding-right: 70%;
    }
    #slogan { 
        padding: 50px 20px 100px 20px;
    }

    #sloganAbout {
        padding-top: 0;
        padding-bottom: 0;
        height: 50vh;
    }

    #sloganAbout h1 {
        font-size: 1.4em;
    }

    #sloganAbout h2 {
        font-size: 1em;
    }

    .cmsQuote {
        padding: 3em;
    }

    #homeSection, #aboutSection, .ourWines, .winesCardsContainer {
        padding-top: 180px;
    }

    .wineTechSheet {
        padding: 120px 20px 20px 20px;
    }
    .modalWineSpec {
        max-width: 1000px;
    }
    
}

@media screen and (max-width: 850px) {
    .wineTechSheet {
        max-width: 700px;
        padding: 120px 10px 20px 10px;
    }
    .modalWineSpec {
        max-width: 700px;
    }

}

@media (max-width: 746px) {

    #logoMenu {
        position: absolute;
        right: 40%;
        top: 10px;
    }
    .modalWineSpec {
        max-width: 700px;
    }

    #aboutAliane div {
        padding-left: 0px;
        padding-right: 0px;
    }
    #sloganMoto h1 {
        font-size: 1.8em;
    }

    #mainSlogan {
        margin: 5px;
        text-align: center;
    }

    #sloganAbout h2 {
        text-align: center;
    }

    #sloganMoto h2 {
        font-size: 1em;
    }

    #sloganMoto h3 {
        font-size: 1em;
    }

    .searchListResults {
        min-width: 100vw;
        padding: 0 !important;
        margin-top: 20px;
    }

    .searchResultIcons {
        height: 40px;
        width: 40x;
    }

    .mapLink {
        padding-top: 0px;
    }

    .domaines {
        padding: 1em;
    }

    #vintners {
        padding-bottom: 0;
        margin-bottom: 0;
    }


    .ContactUs-logo-2 {
        width: 140px !important;
        height: auto;
    }

    .wineTechSheetContent li p {
        letter-spacing: 0.1em;
    }

    .cmsQuote {
        padding: 0em;
    }

    #homeSection, #aboutSection, .ourWines, .winesCardsContainer {
        padding-top: 100px;
    }

}

@media (max-width: 652px) {
    #vintners {
        padding-top: 0;
    }
    #slogan {
        justify-content: flex-start;
    }
    #page-wrap {
        padding: 5px 0px 20px 0px;
        overflow-x: hidden;
    }

    .domaineInfoContainer {
        padding-top: 100px;
    }

    .menu {
        height: 100px;
    }

    .mobileMenu {
        display: flex;
        flex-direction: column;
        top: 100px;
        width: 100vw;
        background-color: rgba(255, 255, 255, 1);
        position: absolute;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    #menuIcon {
        color: #97506d;
        position: absolute;
        top: 25px;
        right: 40px;
        height: 50px;
        width: auto;
    }

    .mobileMenu p {
        color: #BC204B !important;
    }

    #logoMenu {
        display: none;
    }

    .wineTechSheet {
        padding: 20px 20px 20px 20px;
    }


    .leftCell {
        width: auto;
    }

    .rightCell {
        width: auto;
        max-width: 100%;
        padding-left: 0px;
    }

    .wineTechSheetHeaderText {
        margin-left: 10px;
    }

    .wineTechSheetHeaderText p {
        font-size: 10px;
    }

    .wineDenomination p {
        padding-left: 0 !important;
    }

    .menuHover {
        display: none;
    }

    .regionSpec {
        padding: 20px;
    }

    .menuSocialIcons {
        margin-right: 0px;
        margin-left: 0px;
    }

    #socialIconsMenu {
        display: none;
        margin-top: 20px;
    }

    .card {
        border: 0px;
    }

    .contactCard {
        margin: 10px 0px 10px 0px;
        padding: 5px;
        width: 100vw;
        height: auto;
    }

    #grandCruBanner,
    #grandCruAwards {
        padding: 0;
    }

    .gcCredits {
        text-align: center;
    }

    #gcComponent h1 {
        text-align: center;
    }

    .domaineImg {
        width: 100%;
        height: auto;
        padding-top: 10px;
    }

    .regionImg {
        width: 100%;
        height: auto;
    }

    .flexHorizontal {
        justify-content: center;
    }


    #introduction p {
        margin-top: 20px;
    }

    #sloganAbout h1 {
        font-size: 1.4em;
    }

    #sloganAbout h2 {
        font-size: 1em;
    }

    #bioTextContainerNext div {
        padding-left: 0px;
        padding-right: 0px;
    }

    #purpleLogo {
        width: 200px;
        height: auto;
    }

    #footerSlogan {
        padding: 0;
        font-size: 0.5em !important;
    }

    #technicalSheetFlexFooter {
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;
    }

    .technicalSheetFlexFooterBorder {
        display: none;
    }

    #wineTechSheetHeaderLogo {
        padding-right: 0;
    }

    #signature {
        display: none;
    }

    #technicalSheetMobileFooter {
        padding: 0;
        margin: 0;
    }

    .wineTechSheetContent li {
        flex-direction: column;
    }

    #technicalSheetMobileFooter {
        flex-direction: column;
    }

    #footerSlogan {
        padding-top: 10px;
    }

    .wineTechSheetVignette {
        padding: 0;
        padding-bottom: 30px;
        width: 250px;
        height: auto;
    }

    .wineDenomination p {
        text-align: center;
    }
    #mobileLogo {
        display: block;
        width: 240px;
        height: auto;
        margin: auto;
        padding: 20px;
    }
    #winesMobileLogo {
        display: block;
        width: 240px;
        height: auto;
        margin: auto;
    }
}

@media (min-width: 653px) {
    .mobileMenu {
        display: none;
    }

    #menuIcon {
        display: none;
    }

    #technicalSheetMobileFooter {
        display: none;
    }

    #footerSlogan {
        display: none;
    }
}
/* Global rules */
h1,
h2,
h3,
h4 {
    font-family: 'Audrey-Normal';
}

p {
    font-family: 'GillSans-Light';
}

.aliane1 {
    font-family: 'Audrey-Normal';
    text-transform: uppercase;
}

.aliane2 {
    font-family: 'Audrey-Normal';
}

.aliane3 {
    font-family: 'Audrey-Normal';
}

.ck-balloon-panel {
    z-index: 2000 !important;
}
  
a {
    color: rgb(188, 32, 75);
    opacity: 1.0;
    transition: opacity 0.3s;
}

a:hover {
    color: rgb(188, 32, 75);
    opacity: 0.8;

}
  
@font-face {
    font-family: 'Audrey-Normal';
    font-style: normal;
    src: local('Audrey-Normal'),
        url(/static/media/Audrey-Normal.6ecc28f9.ttc) format('truetype');
}

@font-face {
    font-family: 'Audrey-Medium';
    font-style: medium;
    src: local('Audrey-Medium'),
        url(/static/media/Audrey-Medium.c407841f.ttc) format('truetype');
}

@font-face {
    font-family: 'Audrey-Bold';
    font-style: bold;
    src: local('Audrey-Bold'),
        url(/static/media/Audrey-Bold.2576f7b4.ttc) format('truetype');
}

@font-face {
    font-family: 'GillSans-Normal';
    font-style: normal;
    src: local('GillSans-Normal'),
        url(/static/media/GillSans-Normal.d41d8cd9.ttf) format('truetype');
}

@font-face {
    font-family: 'GillSans-Bold';
    font-style: bold;
    src: local('GillSans-Bold'),
        url(/static/media/GillSans-Bold.d41d8cd9.ttf) format('truetype');
}

@font-face {
    font-family: 'GillSans-Light';
    font-style: bold;
    src: local('GillSans-Bold'),
        url(/static/media/GillSans-Bold.d41d8cd9.ttf) format('truetype');
}

@font-face {
    font-family: 'GillSans-Light';
    font-style: normal;
    src: local('GillSans-Light'),
        url(/static/media/GillSans-Light.d41d8cd9.ttf) format('truetype');
}
