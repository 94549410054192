
#bio h1 {
  font-family: 'GillSans-Light';
  color: black;
  text-align: center;
}

#sloganAbout h1 {
  text-transform: none;
  color: black;
  text-align: center;
  font-family: 'GillSans-Light';
  font-size: 2em;
}


#photoAline {
  max-width: 30vw;
  min-width: 300px;
  z-index: -100;
  margin-bottom: 20px;
}

#bioTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: white;
  min-height: 100vh;
  text-align: justify;
}

#bioTextContainer div,
#aboutAliane > div {
  max-width: 80vw;
  min-width: 340px;
  padding-left: 20px;
  padding-right: 20px;
  color: grey;
}

#bioTextContainer div p {
  font-size: 1.3em;
}

#bioTextContainer div h1 {
  text-align: center;
}

#bioTextContainer h1,
#bioTextContainer p {
  color: rgb(46, 46, 46);
}

.contentPanel {
  padding-top: 50px;
  padding-bottom: 50px;
}

.contentPanel div {
  padding-bottom: 10px;
}

#bioTextContainerNext {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: #ffffffc0;
  text-align: justify;
}

#bioTextContainerNext div {
  max-width: 80vw;
  min-width: 340px;
  padding-left: 30px;
  padding-right: 30px;
}

#bioTextContainerNext div p {
  font-size: 1.3em;
}

#aboutAliane {
  min-height: 100vh;
  background-color: #ffffffc0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
}

#aboutAliane div {
  max-width: 80vw;
  min-width: 340px;
  padding-left: 20px;
  padding-right: 20px;
}

#aboutAliane p {
  color: rgb(46, 46, 46);
  margin-top: 0;
  font-size: 1.3em;
}

#springVineyard {
  width: 100%;
  height: auto;
  padding: 0;
}

#purpleLogo {
  width: 15vw;
  padding: 2vw;
}

#bioPhotoSection h1 {
  text-align: center;
  font-family: 'GillSans-Light';
}

#directLinks {
  background-color: white;
  padding: 0px 30px 50px 30px;
}

.aboutDirectLinkToAllWines {
  text-align: center;
  padding: 20px;
  color: rgba(0, 0, 0, 0.8);
  opacity: 0.8;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.aboutDirectLinkToAllWines:hover {
  opacity: 1;
  font-weight: 300;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.whiteborderAbout {
  border-bottom: solid rgba(0, 0, 0, 0.6);
  width: 150px;
  margin: auto;
  border-bottom-width: 1px;
}