#logoMenu {
  height: 70px;
  width: auto;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  height: 90px;
  padding: 0;
  margin: auto;
  z-index: 1000;
  font-family: "Aline";
}

.menu a {
  padding: 20px;
  text-decoration: none;
  font-size: 1.4em;
  font-family: "Audrey-Normal";
  font-weight: 300;
  text-transform: uppercase;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  opacity: 0.7;
  text-decoration: none !important;
}

.menuHover:after {
  content: '';
  display: block;
  margin: auto;
  height: 1px;
  width: 0px;
  background: transparent;
  transition: width 1s ease, background-color .5s ease;
  -webkit-transition: width 1s ease, background-color .5s ease;
  -moz-transition: width 1s ease, background-color .5s ease;
  -ms-transition: width 1s ease, background-color .5s ease;
  -o-transition: width 1s ease, background-color .5s ease;
}

.menu a p {
  margin: 0;
  text-align: center;
  font-weight: 400;
  font-family: "Audrey-Medium";
}

.menuHover:hover {
  transform: scaleX(1, 2);
  -webkit-transform: scaleX(1, 2);
  -moz-transform: scaleX(1, 2);
  -ms-transform: scaleX(1, 2);
  -o-transform: scaleX(1, 2);
  font-size: 1.6em;
  opacity: 1;
}

#gcBanner {
  cursor: pointer;
  width: 40px;
  height: auto;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 25px;
  z-index: 10000;
}

#igLogo {
  cursor: pointer;
  width: 40px;
  height: auto;
  z-index: 10000;
  margin-bottom: 25px;
}

#socialIconsMenu {
  position: absolute;
  right: 20px;
  top: 30px;
}

.menuSocialIcons {
  margin-right: 10px;
  margin-left: 10px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  color: white;
  opacity: 0.9;
}

.menuSocialIcons:hover {
  color: white;
  opacity: 1;
}

.editableBanner p {
  color: white;
}

.saveButton {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.gcContainer #gcBannerText {
  visibility: hidden;
  position: absolute;
  top: 75px;
  width: 220px;
  font-size: 0.8em;
  right: 80;
  transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  text-align: center;
  padding: 5px;
  color: white;
}

.gcContainer:hover #gcBannerText {

  visibility: visible;
  color: white;
  background-color: gray;
  text-align: center;
  transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
}

#gcBanner {
  cursor: pointer;
  width: 110px;
  height: auto;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 10000;
}

.react-reveal {
  height: 80px;
}

.menuMobileLogo {
  margin-top: 20px;
}


.gcContainer[href="/grand-cru"] {
  opacity: 1.0;
}

.socialIconsMenu {
  background-color: white;
  border-radius: 15px;
  opacity: 1.0 !important;
}

.menuWhite .socialIconsMenu {
  background-color: transparent;
  border-radius: 15px;
  opacity: 1.0 !important;
}

#igLogo {
  opacity: 0.7;
}