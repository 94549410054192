.breadcrumb {
    color: #BC204B;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    flex-wrap: wrap;
    padding-left: 60px;
    background-color: white !important;
}

.breadcrumb a {
    text-decoration: none !important;
    font-size: 1.2em;
    white-space: nowrap;
    margin-left: 10px;
}

.chevronRight {
    margin-right: 7px;
    height: 1em;
    width: auto;
    stroke: #BC204B;
    margin-bottom: 7px;

}

.mapLink {
    padding-top: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.breadCrumbLink {
    margin-bottom: 0 !important;
    font-family: "Audrey-Normal";
    opacity: 0.7;
}

.breadCrumbLink:hover {
    margin-bottom: 0 !important;
    opacity: 1;
}