.domaines {
  padding: 3em;
}

.domaineName {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 1.2em;
  width: 50%;
}

.wineTechSheet {
  padding: 20px;
}

.wineTechSheetHeader {
  display: flex;
  align-items: center;
}

.wineTechSheetHeaderText {
  margin-left: 40px;
}

.wineTechSheetHeaderText p {
  color: gray;
  text-align: center;
  font-size: 12px;
}

.wineTechSheetHeaderTextBorder {
  border: 0.5px solid rgba(128, 128, 128, 0.5);
}

.wineTechSheetContent {
  padding-left: 0px !important;
}

.wineTechSheetContent li {
  list-style-type: none;
  padding-bottom: 10px;
  font-weight: 200;
  text-align: justify;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-left: 0 !important;
}

.wineTechSheetContent li p {
  color: gray;

}

.wineDenomination p {
  font-weight: 300 !important;
  text-transform: uppercase;
  padding-left: 10px !important;
  color: black !important;
}

.rightCell {
  max-width: 80%;
  padding-left: 10px;
}

.leftCell {
  width: 18%;
  color: black !important;

}

.domaineSpec {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.domaineProduction {
  list-style-type: none;
  color: grey;
  font-weight: 200;
  max-width: 250px;
  margin-left: 10px;
  margin-right: 10px;
}

.domainesProdContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 50px 0px 50px 0px;
  flex-wrap: wrap;
}

.productionVignette {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.domaineProduction p:hover {
  color: #BC204B;
  cursor: pointer;
}

.domaineTitle {
  color: #BC204B;
  text-transform: uppercase;
  font-size: 1.5em;
  text-align: center;
  font-weight: bold;
}

.domainePlace {
  text-transform: uppercase;
  font-size: 1.2em;
  text-align: center;
  font-weight: bold;
}

.domaineDescription {
  text-align: justify;
  text-justify: newspaper;
  transition: 0.3s;
  padding: 20px;
  color: #25272B;
  font-size: 1.2em;
}

.wineVignette {
  height: auto;
  width: 250px;
}

.wineVignette:hover {
  cursor: pointer;
}

.DomaineHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wineVignetteRegion {
  text-transform: uppercase;
  font-weight: 400 !important;
}

#beauneDetailedMap,
#nuitDetailedMap {
  width: 100%;
  padding: 40px 0px 40px 0px;
}

.image-gallery-thumbnail.active {
  border: none !important;
}

.image-gallery-fullscreen-button:focus {
  outline: none !important;
}