.searchBox {
    z-index: 1001 !important;
    position: absolute;
    left: 20px;
    top: 25px;
}

.searchBox input {
    border: 0px solid;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: white;
    width: 100px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 10000 !important;
}

.input:focus {
    outline: 0 none;
}


.searchListResults {
    list-style-type: none;
    position: absolute;
    z-index: 1001;
    background-color: rgba(250, 250, 250, 0.9);
    min-width: 96vw;
    margin-top: 50px;
}

.searchListResults li {
    margin: 0.7%;
    color: #BC204B;
    cursor: pointer;
}

.searchListResults li:hover {

    color: #BC204B;
}

.searchResultIcons {
    height: 50px;
    width: 50x;
}

.searchHover:hover {
    background-color: rgba(0, 0, 0, 0, 0.5);
}

@media (max-width: 746px) {
    .searchListResults {
        min-width: 100vw;
        padding: 0 !important;
        margin-top: 20px;
    }

    .searchResultIcons {
        height: 40px;
        width: 40x;
    }
}