#selectedVinters {
  color: grey;
  padding: 3em;
}

#selectedVinters {
  color: grey;
  font-size: 1em;
  font-family: 'GillSans-Light', sans-serif;
  font-weight: 300;
}

#selectedVinters h1 {
  color: black;
  font-family: 'GillSans-Light', sans-serif;
}


.card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.regionSpec {
  min-width: 350px;
  padding: 20px;
  text-align: justify;
}

.regionImg {
  width: 300px;
  height: auto;
  background-color: darkgrey;
}

.domaineVignette {
  position: relative;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  cursor: pointer;
  filter: grayscale(100%);
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.domaineVignette:hover {
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  filter: none;
  -webkit-filter: none;
}

.domaineImg {
  padding: 2px;
  width: 300px;
  height: 200px;
  object-fit: cover;
}

.domaineName {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 1.2em;
  width: 50%;
}

.regionWinesList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

#burgundyDetailedMap {
  max-width: 800px;
  padding: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#provenceDetailedMap {
  max-width: 1000px;
  margin-top: 20px;
  padding: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#rhoneDetailedMap {
  max-width: 800px;
  margin-top: 20px;
  padding: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.deleteButton {
  background-color: #BC204B;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
}