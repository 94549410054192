/* Global rules */
h1,
h2,
h3,
h4 {
    font-family: 'Audrey-Normal';
}

p {
    font-family: 'GillSans-Light';
}

.aliane1 {
    font-family: 'Audrey-Normal';
    text-transform: uppercase;
}

.aliane2 {
    font-family: 'Audrey-Normal';
}

.aliane3 {
    font-family: 'Audrey-Normal';
}

.ck-balloon-panel {
    z-index: 2000 !important;
}
  
a {
    color: rgb(188, 32, 75);
    opacity: 1.0;
    transition: opacity 0.3s;
}

a:hover {
    color: rgb(188, 32, 75);
    opacity: 0.8;

}
  
@font-face {
    font-family: 'Audrey-Normal';
    font-style: normal;
    src: local('Audrey-Normal'),
        url(./fonts/Audrey-Normal.ttc) format('truetype');
}

@font-face {
    font-family: 'Audrey-Medium';
    font-style: medium;
    src: local('Audrey-Medium'),
        url(./fonts/Audrey-Medium.ttc) format('truetype');
}

@font-face {
    font-family: 'Audrey-Bold';
    font-style: bold;
    src: local('Audrey-Bold'),
        url(./fonts/Audrey-Bold.ttc) format('truetype');
}

@font-face {
    font-family: 'GillSans-Normal';
    font-style: normal;
    src: local('GillSans-Normal'),
        url(./fonts/GillSans-Normal.ttf) format('truetype');
}

@font-face {
    font-family: 'GillSans-Bold';
    font-style: bold;
    src: local('GillSans-Bold'),
        url(./fonts/GillSans-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'GillSans-Light';
    font-style: bold;
    src: local('GillSans-Bold'),
        url(./fonts/GillSans-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'GillSans-Light';
    font-style: normal;
    src: local('GillSans-Light'),
        url(./fonts/GillSans-Light.ttf) format('truetype');
}