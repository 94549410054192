.winesCardsContainer {
    padding: 0px 10px 100px 10px;
    display: flex;
    flex-direction: column;

}

.winesListByRegionTitle {
    text-transform: uppercase;
    color: #BC204B;
    font-size: 2em;
    border: 1px solid #BC204B;
    text-align: center;
    padding: 10px 20px 10px 20px;
    max-width: 1000px;
}

.winesListByRegionTitle:hover {
    background-color: #BC204B;
    color: white;
    transition: 1s;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -ms-transition: 1s;
    -o-transition: 1s;
    cursor: pointer;
}

.winesListByRegion {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    padding-top: 20px;
}

.winesCardsVignette {
    width: 300px;
    text-align: center;
    margin: 10px;
}

.winesCardsVignette:hover {
    color: #BC204B;
    cursor: pointer;
}

.WinesListContainer {
    padding-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.styles_overlayCenter__YHoO7 {
    z-index: 1001 !important;
}

.styles_modal__gNwvD {
    padding: 0;
}

.allOurWineVignette {
    height: auto;
    width: 250px;
}

#mobileLogo {
    display: none;
}