.mapContainerFlexHorizontal {
  padding-top: 0px;
}

#vintners {
  padding: 3em;
  padding-top: 120px;
  font-family: 'GillSans-Light';
  font-weight: 300;
  font-size: 1.2em;
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

#vintners > div {
  max-width: 1000px;
}

#vintners p {
  color: #25272B;
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  margin: 30px;
  font-size: 1.1em;
}

#vintners h1 {
  color:  #BC204B;
  font-family: 'GillSans-Light';
  text-align: center;
  width: 100%;
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  padding: 50px 50px 10px 50px;
}


.mapContainerFlexHorizontal div h1 {
  color: #BC204B;
  font-family: 'GillSans-Light';
  text-align: center;
  max-width: 1000px;
  text-align: center;
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  padding: 50px;
  margin: auto;
}


.menuSocialIcons {
  color: rgba(151, 80, 109);
}

#directLinkToAllWines {
  text-align: center;
  color: #BC204B !important;
  padding-top: 1em;
  opacity: 0.8;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  padding-left: 20px;
  padding-right: 20px;
}

#directLinkToAllWines:hover {
  opacity: 1;
  font-weight: 300;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

main#page-wrap>div>div>div>h1:nth-of-type(2) {
  padding: 5px;
}

.borderWines {
  border-bottom: solid #BC204B;
  max-width: 150px;
  margin: auto;
  padding: 10px;
  border-bottom-width: 1px;
}