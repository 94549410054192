#gcComponent {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 160px;
  background-color: black;
}

#gcComponent h1 {
  color: white;
  position: relative;
  font-size: 2em;
  padding-top: 10px;
}

#gcComponent > h1:first-of-type {
  max-width: 400px;
  text-align: center;
}

#gcComponent p, .gcContent {
  padding: 5%;
  font-size: 1.3em;
  color: white;
  text-align: justify;
  text-justify: inter-word;
  max-width: 1000px;
  text-indent: 5%;
  max-width: 80vw;
}

.watchIt {
  color: rgb(188, 32, 75);
  font-size: 60px;
}

a:hover {
  text-decoration: none;
}

#grandCruBanner {
  background-color: black;
  width: 100%;
  padding: 0 100px 0 100px;
  margin-top: 30px;
}

#grandCruAwards {
  background-color: black;
  width: 100%;
  padding: 0 100px 0 100px;
}

.gcPartnersLogos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.gcPartnersLogos img {
  width: 200px;
  height: auto;
}

.gcCredits {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 150px 50px 150px;
}

.gcCredits div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-width: 350px;
  align-items: center;
  padding: 10px 15px 10px 15px;
}

.gcCredits h2 {
  color: white;
  font-size: 12px;
}

.gcCredits h1 {
  font-size: 22px !important;
}