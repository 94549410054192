.franceWinesMap {
  width: 75%;
  min-width: 50%;
  margin-top: 30px;
}

.ourWines {
  min-height: 100vh;
}

.backgroundImg {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100vh;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.gmapCustom {
  filter: grayscale();
}
