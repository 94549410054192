.modalWineSpec {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.wineTechSheet {
  padding: 20px 50px 50px 50px;
  max-width: 1080px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.wineTechSheetHeaderText {
  margin-left: 20px;
}

.wineTechSheetHeaderText p {
  color: gray;
  text-align: center;
  font-size: 12px;
}

.wineTechSheetHeaderTextBorder {
  border: 0.5px solid rgba(128, 128, 128, 0.5);
  margin-bottom: 20px;
}

.wineTechSheetContent {
  padding-left: 0px !important;
}

.wineTechSheetContent li {
  list-style-type: none;
  padding-bottom: 10px;
  text-align: justify;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-left: 0 !important;
}

.wineTechSheetContent li p {
  color: gray;

}

.wineDenomination p {
  font-weight: bold !important;
  text-transform: uppercase;
  padding-left: 10px !important;
  color: black !important;
  font-size: 1em;
  font-family: 'GillSans-Light';
  margin-bottom: 40px;
}

.rightCell {
  max-width: 80%;
  padding-left: 10px;
}

.leftCell {
  width: 18%;
  font-family: 'GillSans-Light';
}

#wineTechSheetHeaderLogo {
  height: 100px;
  margin-bottom: 30px;
  padding-right: 90%;
}

.wineTechSheetVignette {
  height: 300px;
  width: auto;
  padding: 30px;
}



#technicalSheetMobileFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

#technicalSheetMobileFooter h4 {
  font-size: 1em !important;
  font-family: "Audrey-Normal" !important;
  padding: 6px;
  margin-top: 8px;
}

.technicalSheetFlexFooterBorder {
  border-right: solid #BC204B;
  width: 10px;
  border-right-width: 1.5px;
  margin: 12px;
}

.mobileSignature{
  padding: 6px;
  width: 80vw;
}

.href h4 {
  color: #25272B;
}

.href h4:hover {
  color: #25272B;
}

#footerSlogan {
  text-transform: uppercase;
  color: #BC204B;
  font-size: 1em !important;
  padding-top: 10px;
  text-align: center;
  font-family: "Audrey-Normal" !important;
  word-spacing: 0.4em;
  letter-spacing: 0.5em;
  padding-left: 80px;
  padding-right: 80px;
}

#signature {
  min-width: 500px;
}