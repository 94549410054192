#slogan {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  padding: 100px 20px 100px 20px;
}

#slogan h1,
#slogan h2,
#slogan h3 {
  color: white;
  word-break: keep-all;
}

#slogan h1 {
  font-size: 2.25em;
  color: white;
  text-align: center;
  word-spacing: 0.2em;
  margin: 0.2em;
}

.vineTitle {
  width: 50px !important;
  height: auto;
  padding: 5px;
}

#homePage {
  position: relative;
}

#introduction {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2em 0 2em;
}

#introduction h1 {
  color: #BC204B;
  text-align: center;
  font-size: 2em;
  text-transform: uppercase;
}

.fullHomeImg {
  width: 100%;
  height: auto;
}

.homeFlexHorizontal {
  display: flex;
  align-items: center;
}

#sloganMoto {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.8);
}

#sloganMoto h3 {
  color: #25272B;
  text-align: center;
  font-size: 1.5em;
  padding: 0 20px 0 20px;
  word-break: keep-all;
}

#balanceLightLoveContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-wrap: wrap;
}

#balanceLightLoveContainer div {
  text-align: center;
  width: 350px;
}

#balanceLightLoveContainer h2 {
  color: #BC204B;
  text-transform: uppercase;
  font-family: 'Audrey-Normal';
}

#balanceLightLoveContainer h1 {
  color: #25272B;
  text-transform: uppercase;
  font-family: "Audrey-Normal";
}

#balanceLightLoveContainer p {
  color: #25272B;
  text-transform: uppercase;
  padding: 10px;
  text-align: center;
  color: #25272B;
  font-size: 1.3em;
}

.borderWidthed {
  border-bottom: solid #BC204B;
  max-width: 40px;
  margin: auto;
  border-bottom-width: 1px;
}

#mainSlogan {
  color: #BC204B;
  margin-top: 1em;
  margin-bottom: 1.5em;
  font-family: "Audrey-Medium";
  font-size: 1.5em;
  text-transform: uppercase;
}

#logoEncart {
  width: 160px;
  height: 200px;
  padding-left: 20px;
}

#flexMoto {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2em;
}

.audreyFontUppercase {
  font-family: "Audrey-Normal";
  text-transform: uppercase;
}

.audreyFont {
  font-family: "Audrey-Normal";
}

.normalAudrey {
  font-family: "Audrey-Normal"
}

.mainSloganContent {
  font-family: "GillSans-Light";
  font-size: 1.3em;
}

.asterisk {
  font-family: "GillSans-Light";
  font-size: 20px;
  display: inline-block;
  top: -15px;
  position: relative;
}